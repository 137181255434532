<template>
  <div class="container">
    <div style="height: 20px"></div>
    <el-form ref="form" label-width="120px" style="width: 500px" v-loading="loading">
      <el-form-item label="用户手机号">
        <el-input v-model="mobileno"></el-input>
      </el-form-item>
      <el-form-item label="订单类型">
        <el-radio-group v-model="ordertype">
          <el-radio :label="2">普通筛查</el-radio>
          <el-radio :label="1">深度筛查</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="可用次数">
        <el-input-number v-model="ordercount" :min="1" :max="1" disabled></el-input-number>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即授权</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

import api from "../../api";

export default {
  name: "OrderObtain",
  data(){
    return{
      mobileno: '',
      ordertype: 2,
      ordercount: 1,
      loading: false,
      loginData: null,
    }
  },
  created() {
    this.loginData = JSON.parse(localStorage.getItem("logindata"));
  },
  methods: {
    onSubmit(){
      if (!this.mobileno) {
        this.$message({
          showClose: true,
          message: "请输入手机号",
          type: "warning",
        });
        return;
      }
      this.$confirm("确定对用户授权可用次数吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        api.insertUserFreeAssessOrder({
          mobileno: this.mobileno,
          ordertype: this.ordertype,
          ordercount: this.ordercount,
          categorycode: this.loginData.categorycode,
        }).then((res) => {
          if (res.data.success === "1") {
            this.$message({
              showClose: true,
              message: '授权成功',
              type: "success",
            });
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
      });
    },
    reset(){
      this.mobileno = '';
      this.ordertype = 2;
      this.ordercount = 1;
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.footer {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
</style>
